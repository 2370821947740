import React, { Component } from 'react'
import { Link } from 'gatsby'
import withSizes from 'react-sizes'

import Container from '../Container/Container'
import Image from '../Image/Image'

class Footer extends Component {
  componentDidMount() {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { isMobile } = this.props

    if (!isMobile && !this.state) {
      console.log('Both isMobile and state are null')
      return <footer className="footer" />
    }

    if (!isMobile || !this.state) {
      return (
        <footer className="footer">
          <Container>
            <nav className="footer__top row between-xs">
              <Link to="/" className="footer__logo">
                <Image filename="logo.png" alt="Ally" />
              </Link>
              <ul className="footer__list">
                <li className="bold">Use cases</li>
                <li>
                  <Link to="/">Onboarding</Link>
                </li>
                <li>
                  <Link to="/">Distributed team</Link>
                </li>
                <li>
                  <Link to="/">Multiple teams</Link>
                </li>
                <li className="bold">
                  <Link to="/pricing">Pricing</Link>
                </li>
              </ul>

              <ul className="footer__list">
                <li className="bold">Ally for...</li>
                <li>
                  <Link to="/">Team Leaders</Link>
                </li>
                <li>
                  <Link to="/">Engineering Manager</Link>
                </li>
                <li>
                  <Link to="/">C-Level & Founders</Link>
                </li>
                <li>
                  <Link to="/">Software Engineers</Link>
                </li>
              </ul>

              <ul className="footer__list">
                <li className="bold">Features</li>
                <li>
                  <Link to="/">Dashboards</Link>
                </li>
                <li>
                  <Link to="/">Activities feed</Link>
                </li>
                <li>
                  <Link to="/">Alerts</Link>
                </li>
                <li>
                  <Link to="/">Goals</Link>
                </li>
                <li>
                  <Link to="/">Notifications</Link>
                </li>
                <li>
                  <Link to="/">Integations</Link>
                </li>
              </ul>

              <ul className="footer__list">
                <li className="bold">Resources</li>
                <li>
                  <Link to="/">Blog</Link>
                </li>
                <li>
                  <Link to="/">Docs</Link>
                </li>
              </ul>

              <ul className="footer__list">
                <li className="bold">Company</li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/">Careers</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul>
            </nav>

            <nav className="footer__bottom">
              <ul className="footer__list footer__list--horizontal">
                <li>
                  <Link to="/">Terms</Link>
                </li>
                <li>
                  <Link to="/">Privacy</Link>
                </li>
                <li>
                  <Link to="/">Status</Link>
                </li>
                <li>
                  <Link to="/">Security</Link>
                </li>
              </ul>
            </nav>
          </Container>
        </footer>
      )
    } else {
      return (
        <footer className="footer footer--mobile">
          <Container>
            <nav className="footer__top row between-xs">
              <div className="col-xs-12">
                <Link to="/" className="footer__logo">
                  <Image filename="logo.png" alt="Ally" />
                </Link>
              </div>
              <div className="col-xs-6">
                <ul className="footer__list">
                  <li className="bold">Use cases</li>
                  <li>
                    <Link to="/">Onboarding</Link>
                  </li>
                  <li>
                    <Link to="/">Distributed team</Link>
                  </li>
                  <li>
                    <Link to="/">Multiple teams</Link>
                  </li>
                  <li className="bold">
                    <Link to="/pricing">Pricing</Link>
                  </li>
                </ul>

                <ul className="footer__list">
                  <li className="bold">Ally for...</li>
                  <li>
                    <Link to="/">Team Leaders</Link>
                  </li>
                  <li>
                    <Link to="/">Engineering Manager</Link>
                  </li>
                  <li>
                    <Link to="/">C-Level & Founders</Link>
                  </li>
                  <li>
                    <Link to="/">Software Engineers</Link>
                  </li>
                </ul>

                <ul className="footer__list">
                  <li className="bold">Features</li>
                  <li>
                    <Link to="/">Dashboards</Link>
                  </li>
                  <li>
                    <Link to="/">Activities feed</Link>
                  </li>
                  <li>
                    <Link to="/">Alerts</Link>
                  </li>
                  <li>
                    <Link to="/">Goals</Link>
                  </li>
                  <li>
                    <Link to="/">Notifications</Link>
                  </li>
                  <li>
                    <Link to="/">Integations</Link>
                  </li>
                </ul>
              </div>
              <div className="col-xs-6">
                <ul className="footer__list">
                  <li className="bold">Company</li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/">Careers</Link>
                  </li>
                  <li>
                    <Link to="/">Contact Us</Link>
                  </li>
                </ul>
                <ul className="footer__list footer__list--last">
                  <li className="bold">Resources</li>
                  <li>
                    <Link to="/">Blog</Link>
                  </li>
                  <li>
                    <Link to="/">Docs</Link>
                  </li>
                </ul>
              </div>
            </nav>

            <nav className="footer__bottom">
              <ul className="footer__list footer__list--horizontal">
                <li>
                  <Link to="/">Terms</Link>
                </li>
                <li>
                  <Link to="/">Privacy</Link>
                </li>
                <li>
                  <Link to="/">Status</Link>
                </li>
                <li>
                  <Link to="/">Security</Link>
                </li>
              </ul>
            </nav>
          </Container>
        </footer>
      )
    }
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 620
})

export default withSizes(mapSizesToProps)(Footer)
